import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import AppHead from '../components/AppHead'

const ChatPage = dynamic(() => import('../modules/chat/ChatPage'), { ssr: false })

const Index: NextPage = () => {
  const router = useRouter()

  useEffect(() => {
    try {
      if (process.env.NEXT_PUBLIC_MAINTENANCE && JSON.parse(process.env.NEXT_PUBLIC_MAINTENANCE)) {
        router.push('/maintenance')
      }
    } catch (e: any) {}
  }, [])

  return (
    <>
      <AppHead />
      <ChatPage />
    </>
  )
}

export default Index
